import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';
import PageBanner from '../components/PageBanner';
import YAMLData from '../../static/content/pricing.yml';
import SEO from "../components/SEO";

function PriceListPage() {
  const imageData = useStaticQuery(graphql`
    query {
      background: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 1400
              )
            }
          }
        }
      }
      banners: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 1400
              )
            }
          }
        }
      }
    }
  `);

  const {
    heading,
    image,
    image_alt,
    backgroundImage_alt,
    pricingOne = {groups: []},
    pricingTwo = {groups: []},
    pricingThree = {groups: []}
  } = YAMLData;

  const headerImage = imageData.banners.edges.find(
    headerImage => "/assets/"+headerImage.node.relativePath === image
  );

  const pricingImage = imageData.background.edges.find(
    pricingImage => "/assets/"+pricingImage.node.relativePath === "/assets/pricing-bg.jpg"
  );

  const ItemFragment = ({value}) => {
    return (
      <>
        {value}<br />
      </>
    )
  }

  const ItemGroup = ({group}) => {
    return (
      <>
        <div className="column is-full">
          <h6 className="has-white-space has-text-center has-no-margin">{group.heading}</h6>
        </div>
        <div className="column grid">
          {group.itemList.map((item, i) => (
            <div className="column is-full grid has-no-padding">
              <div className="column is-mobile-auto has-no-padding-top has-no-padding-bottom">
                <ItemFragment key={i}
                  value={item.item} />
              </div>
              <div className="column is-mobile-shrink is-shrink has-no-wrap has-no-padding-top has-no-padding-bottom">
                <ItemFragment key={i}
                  value={item.price} />
              </div>
            </div>
          ))}
        </div>
        {group.extra &&
          <div className="column is-full">
            <p className="has-white-space">{group.extra}</p>
          </div>
        }
      </>
    )
  }

  return (
    <Layout>
      <SEO title="Fair Beauty - Service Pricelist | Waxing packages available."
        description="You'll love our low prices and student discounts. No appointment is needed, and all walk-ins are welcome." />
      <PageBanner image={headerImage.node.childImageSharp.gatsbyImageData}
        imageAlt={image_alt}
        heading={heading} />
      <section className="section section--pricing">
        <div className="container"
          style={{display: "grid"}}>
          <GatsbyImage style={{gridArea: "1/1", maxWidth: '1200px', height: 'auto'}}
            alt={backgroundImage_alt}
            image={pricingImage.node.childImageSharp.gatsbyImageData} />
          <div className="grid has-text-white"
            style={{gridArea: "1/1", position: "relative"}}>
            <div className="column is-half is-desktop-one-third has-no-padding"
              style={{backgroundColor: "rgba(9, 63, 83, 0.7)", "--col-padding": "1rem"}}>
              {pricingOne.groups.map((group, i) => (
                <ItemGroup key={i}
                  group={group} />
              ))}
            </div>
            <div className="column is-half is-desktop-one-third has-no-padding"
              style={{backgroundColor: "rgba(16, 16, 16, 0.7)", "--col-padding": "1rem"}}>
              {pricingTwo.groups.map((group, i) => (
                <ItemGroup key={i}
                  group={group} />
              ))}
            </div>
            <div className="column is-desktop-one-third has-no-padding"
              style={{backgroundColor: "rgba(255, 255, 255, 0.2)", "--col-padding": "1rem"}}>
              {pricingThree.groups.map((group, i) => (
                <ItemGroup key={i}
                  group={group} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PriceListPage;
