import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

function PageBanner({
  image,
  imageAlt,
  heading
}) {
  return (
    <section className="hero hero--page">
      <div className="hero__content has-no-padding"
        style={{display: "grid", height: '400px'}}>
        <GatsbyImage style={{gridArea: "1/1", height: '400px'}}
          alt={imageAlt}
          image={image} />
        <div className="background-overlay"></div>
        <div className="container grid"
          style={{gridArea: "1/1", position: "relative"}}>
          <div className="column is-full">
            <h1>{heading}</h1>
          </div>
        </div>
        <div className="border-overlay"></div>
      </div>
    </section>
  )
}

export default PageBanner;
